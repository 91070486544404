// firebase.js

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getDatabase } from "firebase/database"; 

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {

  apiKey: "AIzaSyByo2LRKL_JnDN2_SrZ0DVB-Be81N0sPEo",

  authDomain: "rlsm-9b73c.firebaseapp.com",

  databaseURL: "https://rlsm-9b73c-default-rtdb.firebaseio.com",

  projectId: "rlsm-9b73c",

  storageBucket: "rlsm-9b73c.appspot.com",

  messagingSenderId: "941235000386",

  appId: "1:941235000386:web:725a7211438f7c493949db",

  measurementId: "G-HX43VRWYWB"

};



// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getDatabase(app); 

// Export the initialized Firebase app
export { app, db };
